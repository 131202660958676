import { ButtonProps } from '@mui/material';
import dayjs from 'dayjs';
import { DayCalendarFilterOptionType } from '../CalendarFilterOptionType';
import { DdiziType } from '../DdiziType';
import { ExchangeCouponType } from '../ExchangeCouponType';
import { GuestVisitType } from '../GuestVisitType';
import { CartAggregateType } from '../OrderAggregateType';
import { Pageable } from '../PageType';
import { PaymentInfoType } from '../PaymentType';
import { CartType } from './Cart';

export type AdminOrderMgFilterType = {
	storeIdList?: number[];
	posIdList?: number[];
	page: number;
	size?: number;
	search?: string;
	calendarOption: DayCalendarFilterOptionType;
	sort?: Pageable['sort'];
	status?: OrderType['status'];
	type?: OrderType['type'];
};

export const OrderTypeArray = ['ORDER', 'JUNGSAN', 'KYULSAN', 'SESSION'] as const;
export const OrderTypeLabel: Record<(typeof OrderTypeArray)[number], string> = {
	ORDER: '일반 주문',
	JUNGSAN: '정산 주문',
	KYULSAN: '결산 주문',
	SESSION: '세션 주문',
};

export interface OrderType {
	id: string;
	dailySeq: number;
	type: (typeof OrderTypeArray)[number];
	posId: number;
	storeId: number;

	// OrderStatus 랑 다름
	status: OrderStatusType;

	created: Date; // 주문이 시작된 시점 = POS 에서 호출 이후 첫 결제 시도 / 보류가 있었던 순간
	completed?: Date; // 주문이 완료된 시점 = 마지막 결제 시점
	canceled?: Date;

	aggregate: CartAggregateType;

	guestMemberInfo?: {
		memberId: number;
		name: string;
		telephone: string;
		sunbun?: number;
		guestVisitId?: number;
		users?: GuestVisitType['users'];
		appUserId?: string;
	};

	startWithPublish: boolean;
	pointMemberUserId?: string;
	points: number;
	pointProcessed: boolean;
	kioskUserTelephone?: string;
}

export type OrderTypeWithDdizisAndExchanges = OrderType & {
	ddizis: DdiziType[];
	exchangeCoupons: ExchangeCouponType[];
};

export type OrderTypeWithPayments = OrderType & {
	payments: PaymentInfoType[];
};

export type OrderDetailType = OrderType & {
	ddizis: DdiziType[];
	exchangeCoupons: ExchangeCouponType[];
	cart: CartType;
	payments: PaymentInfoType[];
};

export const OrderStatusTypeArray = [
	// 'POSTPONED',
	// 결제가 완료
	'COMPLETED',
	// 결제가 완료되어 티켓 인쇄도 완료되었지만 부분취소가 된 경우에 해당
	'PARTIALLY_CANCELED',
	// 결제가 미완
	// 'PROCESSING',
	'NOT_COMPLETED',
	'CANCELED',
] as const;
export type OrderStatusType = (typeof OrderStatusTypeArray)[number];
export const OrderStatusTypeLabel: Record<OrderStatusType, string> = {
	// POSTPONED: '결제미완',
	CANCELED: '취소',
	COMPLETED: '주문완료',
	NOT_COMPLETED: '결제미완',
	PARTIALLY_CANCELED: '재결제필요',
};
export const OrderStatusTypeColor: Record<
	OrderStatusType,
	'error' | 'warning' | 'success' | 'inherit'
> = {
	COMPLETED: 'success',
	PARTIALLY_CANCELED: 'warning',
	NOT_COMPLETED: 'error',
	// POSTPONED: 'error',
	CANCELED: 'inherit',
};

type PointStatusType = '적립 완료' | '적립 예정' | '적립 불가' | '적립 가능' | '적립 취소됨';
export const getPointStatus = (order: OrderType): PointStatusType => {
	const isPointEditableConstant = isPointEditable(order);
	if (!isPointEditableConstant) {
		if (order.status !== 'COMPLETED' && order.pointProcessed) return '적립 취소됨';
		return order.pointProcessed ? '적립 완료' : '적립 불가';
	}

	return order.pointMemberUserId ? '적립 예정' : '적립 가능';
};

const canEditPointMember = (date?: Date) => {
	return !!date && dayjs().subtract(14, 'days').startOf('day').isBefore(date);
};

const isPointEditable = (order: OrderType): boolean => {
	if (
		canEditPointMember(order.completed) &&
		order.status === 'COMPLETED' &&
		order.type !== 'KYULSAN'
	)
		return true;
	else return false;
};

export const canEditPoint: Record<PointStatusType, boolean> = {
	'적립 완료': false,
	'적립 예정': true,
	'적립 불가': false,
	'적립 가능': true,
	'적립 취소됨': false,
};

export const PointStatusColor: Record<PointStatusType, ButtonProps['color']> = {
	'적립 완료': 'success',
	'적립 예정': 'primary',
	'적립 불가': 'error',
	'적립 가능': 'warning',
	'적립 취소됨': 'error',
};

export const calculate주문총면세매출 = (order: OrderDetailType) => {
	return order.cart.lines.reduce(
		(arr, item) => arr + (item.productAdditionalInfo.taxFree ? item.price * item.quantity : 0),
		0
	);
};

export const get분할결제비율 = (주문총금액: number, 분할결제금액: number) => {
	return 분할결제금액 / 주문총금액;
};
