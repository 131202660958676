import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { AdminSessionGroupType, SessionGroupTypeForSale, SessionLineType } from '../types';

const prefix = 'api/sessions';
const rootUrl = process.env['NX_POS_API_URL'] || 'http://localhost:8080';

export const SessionsApi = {
	getAllForAdminAsync: async ({ storeIdList }: { storeIdList?: number[] }) => {
		const result = (
			await posAxiosClient.get<AdminSessionGroupType[]>(`${rootUrl}/${prefix}`, {
				params: { storeIdList },
			})
		).data;

		return result;
	},
	getAllForSalesAsync: async ({ storeId }: { storeId: number }) => {
		const result = (
			await posAxiosClient.get<SessionGroupTypeForSale[]>(`${rootUrl}/${prefix}/sales`, {
				params: { storeId },
			})
		).data;

		return result;
	},
	createSessionGroupAsync: async ({
		sessionGroup,
	}: {
		sessionGroup: Omit<AdminSessionGroupType, 'id'>;
	}) => {
		const result = (
			await posAxiosClient.post<AdminSessionGroupType>(`${rootUrl}/${prefix}`, sessionGroup)
		).data;

		return result;
	},
	updateSessionGroupAsync: async ({ sessionGroup }: { sessionGroup: AdminSessionGroupType }) => {
		const result = (
			await posAxiosClient.put<AdminSessionGroupType>(`${rootUrl}/${prefix}`, sessionGroup)
		).data;

		return result;
	},
	deleteSessionGroupAsync: async ({ idList }: { idList: number[] }) => {
		const result = (
			await posAxiosClient.delete(`${rootUrl}/${prefix}`, {
				params: { idList },
			})
		).data;

		return result;
	},
	lines: {
		getSessionLinesAsync: async ({ storeId }: { storeId: number }) => {
			const result = (
				await posAxiosClient.get<SessionLineType[]>(`${rootUrl}/${prefix}/lines`, {
					params: { storeId },
				})
			).data;

			return result;
		},
		occupySessionLineAsync: async ({
			storeId,
			sessionId,
			userId,
			externalKey,
			capacity,
		}: {
			storeId: number;
			sessionId: string;
			userId: string;
			externalKey: string;
			capacity: number;
		}) => {
			const result = (
				await posAxiosClient.post<{
					message: string;
					sessionId: string;
					seq?: number;
				}>(`${rootUrl}/${prefix}/lines/occupy`, undefined, {
					params: { storeId, sessionId, userId, externalKey, capacity },
				})
			).data;

			return result;
		},
		resolveSessionLineByExternalKeyAsync: async ({
			storeId,
			sessionId,
			keyList,
			includeOrdered,
		}: {
			storeId: number;
			sessionId: string;
			keyList: string[];
			includeOrdered: boolean;
		}) => {
			const result = (
				await posAxiosClient.post<void>(`${rootUrl}/${prefix}/lines/resolve/key`, undefined, {
					params: {
						storeId,
						sessionId,
						keyList,
						includeOrdered,
					},
				})
			).data;

			return result;
		},
		resolveSessionLineByUserIdAsync: async ({
			storeId,
			userId,
		}: {
			storeId: number;
			userId: string;
		}) => {
			const result = (
				await posAxiosClient.post<SessionLineType[]>(
					`${rootUrl}/${prefix}/lines/resolve/user`,
					undefined,
					{
						params: {
							storeId,
							userId,
						},
					}
				)
			).data;

			return result;
		},
	},
};
