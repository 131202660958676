import { CloseOutlined } from '@ant-design/icons';
import {
	authState,
	ExchangeCouponPrintEventHandler,
	OrderQueryState,
	OrderState,
	PrintReceiptState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { Button, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DdiziPrintHandler } from '../../order/PrintHandler/DdiziPrintHandler';
import { ExchangeCouponPrintHandler } from '../../order/PrintHandler/ExchangeCouponPrintHandler';
import { JungSanDdiziResultDisplay } from './JungSanDdiziResultDisplay';
import { PointAcc } from './PointAcc';
import { PosElectronStoreState } from '@kinderlabs-pos/state';
import { usePrintHwasung } from '../../hwasung/usePrintHwasung';
import { CartAggregateType, getAggregate } from '@kinderlabs-pos/shared-data-type';
import { getUuidV4 } from '@kinderlabs-pos/shared-util';
import { useQuery } from '@tanstack/react-query';

export interface IPaymentResultProps {
	mode: 'order_print' | 'order_not_print' | 'jungsan';
}
export const PaymentResult: React.FC<IPaymentResultProps> = ({ mode }) => {
	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const isUmmCompany = useAtomValue(authState.enterpriseInfo)?.id === 'ummcompany';
	const navigate = useNavigate();
	const order = useAtomValue(OrderState.value);
	const 영수증자동출력 = PrintReceiptState.use영수증자동출력();
	const kitchenSystem = StoreInfoState.useKitchenSystemInfo();

	const electronSettings = PosElectronStoreState.usePosMainPageLocalSettings();

	const 주방주문증크게 = electronSettings.data?.usingLargeTextOnKitchenReceiptPrinter ?? false;
	const 결제완료후자동으로이전페이지이동 =
		electronSettings.data?.goAutoMainPageAfterCompleteOrder ?? false;

	// const clear = useSetAtom(OrderState.actions.clear);

	const { printCafe주문서Async: printCafeReceiptAsync } = PrintReceiptState.useCafePrinter({
		storeId,
		deviceType: 'POS',
	});

	const { printKitchen주문서Async: printKitchenReceiptAsync } = PrintReceiptState.useKitchenPrinter(
		{ storeId, deviceType: 'POS' }
	);

	const isDdiziComplete = OrderState.printing.ddizis.useIsPrintingComplete();
	const isExchangeCouponsComplete = OrderState.printing.exchangeCoupons.useIsPrintingComplete();

	const [프린트도끝났니, set프린트도끝났니] = useState(false);
	const printComplete = isDdiziComplete && isExchangeCouponsComplete && 프린트도끝났니;

	const resumePrinting = ExchangeCouponPrintEventHandler.useResume();

	useEffect(() => {
		(async () => {
			switch (mode) {
				case 'order_print': {
					await 영수증자동출력({ order, payments: order.payments });
					resumePrinting();

					if (!kitchenSystem) {
						await printCafeReceiptAsync({ order, 주방주문증크게 });
						await printKitchenReceiptAsync({ order, 주방주문증크게 });
					}

					set프린트도끝났니(true);

					break;
				}
				case 'jungsan': {
					await 영수증자동출력({ order, payments: order.payments });

					set프린트도끝났니(true);
					break;
				}
			}
		})();
	}, [mode, kitchenSystem]);

	const clear = OrderState.actions.useClear();
	useEffect(() => {
		return () => {
			clear();
			if (goToPrevPageTimeOut) clearTimeout(goToPrevPageTimeOut);
		};
	}, []);

	useEffect(() => {
		if (printComplete && 결제완료후자동으로이전페이지이동) {
			// 2초후
			goToPrevPageTimeOut = setTimeout(() => {
				navigate(-1);
			}, 1000);
		}
	}, [printComplete]);

	return (
		<Stack
			flex={1}
			spacing={1}
			height={'100%'}>
			<Typography variant={'h1'}>결제 완료</Typography>
			<Stack
				flex={1}
				spacing={1}
				overflow={'auto'}>
				<PointAcc mode={'immediate'} />
				<DdiziPrintHandler StackProps={{ flex: 1, sx: { overflowY: 'auto' } }} />
				<ExchangeCouponPrintHandler StackProps={{ flex: 1, sx: { overflowY: 'auto' } }} />
			</Stack>
			{mode === 'jungsan' && <JungSanDdiziResultDisplay />}
			{isUmmCompany && order.id && <화성프린터뽑아보자 orderId={order.id} />}
			<Stack
				direction={'row'}
				spacing={1}>
				{mode === 'order_print' && (
					<Button
						sx={{ flex: 1 }}
						disabled={printComplete}
						startIcon={<CloseOutlined style={{ color: 'inherit' }} />}
						color={'error'}
						variant='outlined'
						onClick={() => navigate(-1)}>
						{'인쇄 취소 및 돌아가기'}
					</Button>
				)}
				<Button
					sx={{ flex: 1 }}
					color={'primary'}
					variant='contained'
					disabled={mode === 'order_print' && !printComplete}
					onClick={() => navigate(-1)}>
					{'이전 화면으로 돌아가기'}
				</Button>
			</Stack>
		</Stack>
	);
};

let goToPrevPageTimeOut: NodeJS.Timeout | null = null;

const 화성프린터뽑아보자 = ({ orderId }: { orderId: string }) => {
	const printHwasung = usePrintHwasung();

	const orderData = useQuery(OrderQueryState.keys.detailByOrderId(orderId));
	useEffect(() => {
		if (orderData.data) {
			printHwasung({ order: orderData.data });
		}
	}, [orderData.data]);

	return null;
};
