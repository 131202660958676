import {
	CartLineInfoType,
	CartLineProductInfoType,
	getProductAdditionalInfo,
	ProductInfoType,
	ProductTypeLabel,
} from '@kinderlabs-pos/shared-data-type';

export type CartLineSessionProductActionType =
	| {
			type: 'ADD';
			products: {
				cartLineId: string;
				productInfo: ProductInfoType;
				sessionId: string;
				sessionBoardIndex: number;
				imageUrl?: string; // 키오스크만 사용
			}[];
			onComplete?: (cartLine?: CartLineProductInfoType[]) => void;
	  }
	| {
			type: 'REMOVE';
			cartLineIdList: string[];
			onComplete?: () => void;
	  };

const cartLineProductReducer = (
	prev: CartLineInfoType[],
	action: CartLineSessionProductActionType
): CartLineInfoType[] => {
	switch (action.type) {
		case 'ADD': {
			const inputCartLines = action.products.map((product) =>
				createProductCartLineInput({
					cartLineId: product.cartLineId,
					productInfo: product.productInfo,
					imageUrl: product.imageUrl,
					sessionBoardIndex: product.sessionBoardIndex,
					sessionId: product.sessionId,
				})
			);

			action.onComplete && action.onComplete(JSON.parse(JSON.stringify(inputCartLines)));

			return [...prev, ...inputCartLines];
		}
		case 'REMOVE': {
			return prev.filter((cl) => !action.cartLineIdList.includes(cl.id));
		}
	}
};

const createProductCartLineInput = ({
	cartLineId,
	productInfo,
	sessionId,
	sessionBoardIndex,
	imageUrl,
}: {
	cartLineId: string;
	productInfo: ProductInfoType;
	sessionId: string;
	sessionBoardIndex: number;
	imageUrl?: string; // KIOSK 전용
}): CartLineProductInfoType => {
	return {
		id: cartLineId,
		type: 'PRODUCT',
		name: productInfo.name,
		price: productInfo.price,
		productInfoId: productInfo.id,
		productInfo: {
			category: ProductTypeLabel[productInfo.type],
			isForKitchen: productInfo.isForKitchen || productInfo.type === 'SET',

			ddiziInfo: productInfo.ddiziInfo,
			exchangeInfo: productInfo.exchangeInfo,
		},
		sessionInfo: {
			sessionId,
			sessionBoardIndex,
		},
		productAdditionalInfo: getProductAdditionalInfo(productInfo),
		// productDiscountInfo: productDiscountInfo
		// 	? {
		// 			...productDiscountInfo,
		// 			evaluatedValue: CartLineState.utils.getUnitDiscount({
		// 				price: productInfo.price,
		// 				productDiscountInfo: productDiscountInfo,
		// 			}),
		// 	  }
		// 	: undefined,
		// ticketUsageId: undefined,
		targetCartLineId: undefined,
		quantity: 1,
		imageUrl,
		i18n: productInfo.i18nName,
	};
};

export const CartLineSessionProductState = {
	reducer: cartLineProductReducer,
};
