import {
	ExternalWindowKeyType,
	IpcPosMainPageApiType,
	PosLocalSettingsType,
	ipcPosMainPageApiName,
} from '@kinderlabs-pos/ipc/common';
import { HAS_NO_ELECTRON, isOnElectron } from './const';

const sendSubPageMessage: IpcPosMainPageApiType['invokeSubpageMessage'] = (props) =>
	window &&
	window[ipcPosMainPageApiName] &&
	window[ipcPosMainPageApiName].invokeSubpageMessage(props);

const invokeExternalWindow = (args: ExternalWindowKeyType) =>
	window &&
	window[ipcPosMainPageApiName] &&
	window[ipcPosMainPageApiName].parkingLot.invokeMessage(args);

const saveSettings = (localSettings: PosLocalSettingsType) =>
	isOnElectron
		? window[ipcPosMainPageApiName].localSettings.invokeSaveLocalSettings(localSettings)
		: HAS_NO_ELECTRON;

const loadSettings = (): Promise<PosLocalSettingsType> =>
	isOnElectron
		? window[ipcPosMainPageApiName].localSettings.invokeLoadLocalSettings()
		: Promise.resolve({
				recentLoginId: undefined,
				recentBells: [],
				useNewLabelPrinter1: false,
				useNewLabelPrinter2: false,
				printStacks: ['CARD', 'PAYMENT', 'PRODUCT'],
				usingBarcodeModel: 'QRCODE',
				defaultSidebar: 'VISIT',
				영수증너비: 42,
		  });

export const ReactPosMainPageIPC = {
	sendSubPageMessage,
	externalWindow: {
		showParkingLotWindow: () =>
			invokeExternalWindow({
				key: 'parking-lot',
				type: 'open',
			}),
		hideParkingLotWindow: () =>
			invokeExternalWindow({
				key: 'parking-lot',
				type: 'close',
			}),
		changeParkingLotWindow: (url: string) =>
			invokeExternalWindow({
				key: 'parking-lot',
				type: 'url',
				url,
			}),
	},
	localSettings: {
		saveSettings,
		loadSettings,
	},
};
