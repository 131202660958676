import { ReactRPIPC } from '@kinderlabs-pos/ipc/react';
import { CustomReceiptRequestType, DeviceType } from '@kinderlabs-pos/shared-data-type';
import { useAtomValue } from 'jotai';
import { StoreInfoState } from '../StoreInfoState';
import { sleep } from '@kinderlabs-pos/shared-util';
import { KioskElectronStoreState, PosElectronStoreState } from '../ElectronStoreState';

export const useCustomReceiptPrinter = ({ deviceType }: { deviceType: DeviceType }) => {
	const { receiptPrinterList: RPList, printMode } = StoreInfoState.useCurDeviceInfoForOrder();
	const localSettings =
		deviceType === 'POS'
			? PosElectronStoreState.usePosMainPageLocalSettings().data
			: KioskElectronStoreState.useKioskMainPageLocalSettings().data;
	return {
		printCustomReceiptAsync: async ({
			receiptRequestData,
		}: {
			receiptRequestData: CustomReceiptRequestType;
		}) => {
			const CandidatePrinterInfos = RPList.filter(
				(p) => p.hardwareType.usageType === 'NORMAL' && p.isActive
			);
			for (const printerInfo of CandidatePrinterInfos) {
				printMode === 'BIXOLONSDK' && (await sleep(500));
				await ReactRPIPC.invoke커스텀영수증출력({
					...receiptRequestData,
					hardwareInfo: {
						deviceType: deviceType,
						printerInfo: printerInfo,
						printMode,
						영수증너비: localSettings ? localSettings.영수증너비 : deviceType === 'POS' ? 42 : 48,
					},
				});
			}
		},
	};
};

// JM : BXLN 레거시 코드 백업
// if (RPList.length === 0) {
//   await ReactPosIPC.hardware.invoke커스텀영수증출력({
//     ...receiptRequestData,
//     deviceType: deviceType,
//     printMode: 'BIXOLONSDK',
//     deviceInfo: undefined,
//   });
// }
