import {
	PosStatusDetailedType,
	VaultCashCheckType,
	VaultCashStatusType,
} from '@kinderlabs-pos/shared-data-type';
import { getDateFormatWithHyphen, getDateTimeFormatWithHyphen } from '@kinderlabs-pos/shared-util';
import { AdminPosInfoState, PosElectronStoreState, VaultCashState } from '@kinderlabs-pos/state';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogProps,
	DialogTitle,
	Divider,
	FormControlLabel,
	Radio,
	RadioGroup,
	Stack,
	styled,
	TextField,
	Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { ReactRPIPC } from '@kinderlabs-pos/ipc/react';
import { VaultcashCheckerModeType, VaultcashFunctionType } from './common';
import { FinalFailureDialog } from './FinalFailureDialog';
import { FinalResultDialog } from './FinalResultDialog';

const today = dayjs();
export const FinalCheckDialog: React.FC<
	Pick<VaultcashFunctionType, 'total' | 'numBills'> &
		DialogProps & {
			storeId: number;
			posId: number;
			closeCheckDialog: () => void;
			checkMode: VaultcashCheckerModeType;
			setPosState?: React.Dispatch<React.SetStateAction<PosStatusDetailedType | undefined>>;
		}
> = ({ storeId, posId, checkMode, total, numBills, closeCheckDialog, open, setPosState }) => {
	const [openFinalDialog, setOpenFinalDialog] = useState(false);
	const [shouldPrint, setShouldPrint] = useState(false);
	const [memo, setMemo] = useState('');
	const electronSettings = PosElectronStoreState.usePosMainPageLocalSettings();

	const handlePostMutation = VaultCashState.actions.useChangePosStatus();
	const { data: selectedPosInfo } = useQuery({
		...AdminPosInfoState.keys.detail({ storeId, posId }),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	const { data: cashStatus } = useQuery(VaultCashState.keys.posStatus(checkMode.posId));

	const { data: cashSales } = useQuery({
		...VaultCashState.keys.getCashSales({
			posId: checkMode.posId,
			from: getDateFormatWithHyphen(today.toDate()),
			to: getDateFormatWithHyphen(today.toDate()),
		}),
	});

	const { data: histories } = useQuery({
		...VaultCashState.keys.posHistory({
			params: {
				storeIdList: [storeId],
				posId: posId,
				from: today,
				to: today,
				sort: { name: 'createdDate', direction: 'desc' },
			},
		}),
		refetchOnWindowFocus: true,
		keepPreviousData: true,
	});

	type alertStateType = { alertOpen: boolean; alertMessage: string };
	const [alertState, setAlertState] = useState<alertStateType>({
		alertOpen: false,
		alertMessage: 'no error',
	});

	useEffect(() => {
		if (shouldPrint && histories && selectedPosInfo?.receiptPrinterList) {
			(async () => {
				const CandidatePrinterInfos = selectedPosInfo.receiptPrinterList.filter(
					(p) => p.hardwareType.usageType === 'NORMAL' && p.isActive
				);
				for (const printerInfo of CandidatePrinterInfos) {
					await ReactRPIPC.invoke시재점검표출력({
						hardwareInfo: {
							deviceType: 'POS',
							printerInfo: printerInfo,
							printMode: selectedPosInfo.printMode,
							영수증너비: electronSettings.data?.영수증너비 ?? 42,
						},
						...histories.content[0],
						cashSales: cashSales?.cashAmount,
						withdraws: cashStatus?.withdraws,
						deposits: cashStatus?.deposits,
						openCashAmount: cashStatus?.openInfo.totalCash,
					});
				}
			})();
			setShouldPrint(true);
		}
	}, [histories]);

	const handlePost = async () => {
		const payload: VaultCashStatusType = {
			storeId: checkMode.storeId,
			posId: checkMode.posId,
			cashUnits: numBills,
			countedAmount: total,
			memo: memo,
			registerDate: getDateTimeFormatWithHyphen(new Date()),
		};

		if (!total && (checkMode.type === 'DEPOSIT' || checkMode.type === 'WITHDRAW')) {
			setAlertState({
				alertOpen: true,
				alertMessage: '입/출금액은 0원이 될 수 없습니다.',
			});
			return;
		}
		await handlePostMutation.mutate(
			{ checkType: checkMode.type, payload },
			{
				onSuccess: () => {
					setOpenFinalDialog(true);
					setShouldPrint(true);
					setPosState && checkMode.type === 'CLOSE' && setPosState('notOpened');
					setPosState && checkMode.type === 'OPEN' && setPosState('alreadyOpened');
				},
				onError: (error, variables, context) => {
					//@ts-ignore
					setAlertState({ alertOpen: true, alertMessage: error.message });
				},
			}
		);
	};

	return (
		<>
			<Dialog
				open={open}
				fullWidth={true}>
				<DialogTitle>
					<Typography variant='h1'>시재 점검 확인</Typography>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Stack direction='column'>
							<Stack
								direction='row'
								justifyContent='space-between'>
								<Typography variant='subtitle1'>점검시각</Typography>
								<Typography variant='subtitle1'>
									{dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')}
								</Typography>
							</Stack>
							<Stack
								direction='row'
								justifyContent='space-between'>
								<Typography variant='subtitle1'>입력금액</Typography>
								<Typography
									variant='h4'
									color={'red'}>
									{total.toLocaleString()} 원
								</Typography>
							</Stack>
							<MemoInput
								memo={memo}
								setMemo={setMemo}
								type={checkMode.type}
							/>
						</Stack>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handlePost}
						variant='contained'>
						확인
					</Button>
					<Button
						onClick={closeCheckDialog}
						variant='outlined'>
						취소
					</Button>
				</DialogActions>
			</Dialog>
			<FinalResultDialog
				open={openFinalDialog}
				closeDialog={() => setOpenFinalDialog(false)}
				closeCheckDialog={closeCheckDialog}
				checkMode={checkMode}
				cashStatus={cashStatus ?? null}
				cashSales={cashSales ?? null}
			/>
			<FinalFailureDialog
				alertState={alertState}
				setAlertState={setAlertState}
			/>
		</>
	);
};

type MemoInputType = {
	memo: string;
	setMemo: React.Dispatch<React.SetStateAction<string>>;
	type: VaultCashCheckType;
};
const MemoInput: React.FC<MemoInputType> = ({ memo, setMemo, type }) => {
	const [useText, setUseText] = useState(false);
	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setMemo(e.target.value);
	};

	useEffect(() => {
		switch (type) {
			case 'OPEN':
			case 'CHECK':
			case 'CLOSE':
				break;
			case 'DEPOSIT':
				setMemo('준비금 부족으로 인한 추가 입금');
				break;
			case 'WITHDRAW':
				setMemo('준비금 충분으로 인한 출금');
				break;
		}
	}, []);

	return (
		<Stack
			direction='column'
			spacing={1}>
			<Divider sx={{ margin: 1 }} />
			<Typography
				variant='h2'
				color={'black'}>
				메모
			</Typography>
			{type === 'DEPOSIT' && (
				<RadioGroup defaultValue='option1'>
					<FormControlLabel
						value='option1'
						control={<Radio />}
						label='준비금 부족으로 인한 추가 입금'
						onClick={() => {
							setUseText(false);
							setMemo('준비금 부족으로 인한 추가 입금');
						}}
						sx={{ height: 30 }}
					/>
					<FormControlLabel
						value='other'
						control={<Radio />}
						label='직접 입력'
						onClick={() => setUseText(true)}
						sx={{ height: 30 }}
					/>
				</RadioGroup>
			)}
			{type === 'WITHDRAW' && (
				<RadioGroup defaultValue='option1'>
					<FormControlLabel
						value='option1'
						control={<Radio />}
						label='준비금 충분으로 인한 출금'
						onClick={() => {
							setUseText(false);
							setMemo('준비금 충분으로 인한 출금');
						}}
						sx={{ height: 30 }}
					/>
					<FormControlLabel
						value='other'
						control={<Radio />}
						label='직접 입력'
						onClick={() => setUseText(true)}
						sx={{ height: 30 }}
					/>
				</RadioGroup>
			)}
			{(type === 'CHECK' || type === 'OPEN' || type === 'CLOSE') && (
				<RadioGroup defaultValue='option1'>
					<FormControlLabel
						value='option1'
						control={<Radio />}
						label='생략'
						onClick={() => {
							setUseText(false);
							setMemo('');
						}}
						sx={{ height: 30 }}
					/>
					<FormControlLabel
						value='other'
						control={<Radio />}
						label='직접 입력'
						onClick={() => setUseText(true)}
						sx={{ height: 30 }}
					/>
				</RadioGroup>
			)}
			<StyledMemoTextField
				value={memo}
				variant='outlined'
				onChange={handleChange}
				focused={useText}
				disabled={!useText}
			/>
			<GuideWords type={type} />
		</Stack>
	);
};

const GuideWords = ({ type }: { type: VaultCashCheckType }) => {
	return (
		<>
			{(type === 'DEPOSIT' || type === 'WITHDRAW') && (
				<Stack>
					<Typography
						variant='h5'
						color='red'>
						※ 시재 입출금은 과부족에 영향을 미치지 않습니다.
					</Typography>
					<Typography
						variant='h5'
						color='red'>
						※ 기입 오류 등 재점검 시 '시재 중간 점검'을 활용해주세요
					</Typography>
				</Stack>
			)}
			{(type === 'OPEN' || type === 'CLOSE' || type === 'CHECK') && (
				<Stack>
					<Typography
						variant='h5'
						color='red'>
						※ 특이사항 발생시 메모를 기입해 주세요
					</Typography>
				</Stack>
			)}
		</>
	);
};

const StyledMemoTextField = styled(TextField)(() => ({
	'& .MuiInputBase-root': {
		height: 35,
	},
	'& .MuiInputBase-input': {
		textAlign: 'left',
	},
}));
