import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { SessionsQuery } from '../query';
import { SessionGroupTypeForSale, SessionTypeForSale } from '../types';

export const useSessionGroupMapBySessionId = (storeId?: number) => {
	const { data: sessionGroups } = useQuery({
		...SessionsQuery.query.sessions.allForSale({ storeId }),
	});

	return useMemo(() => {
		return (
			sessionGroups?.reduce(
				(acc, sessionGroup) => {
					sessionGroup.sessionList.forEach((session) => {
						acc[session.sessionId] = {
							sessionGroupInfo: sessionGroup,
							sessionInfo: session,
						};
					});
					return acc;
				},
				{} as Record<
					string,
					{
						sessionGroupInfo: SessionGroupTypeForSale;
						sessionInfo: SessionTypeForSale;
					}
				>
			) ?? {}
		);
	}, [sessionGroups]);
};
