import { ReactPosMainPageIPC, ReactRPIPC } from '@kinderlabs-pos/ipc/react';
import {
	DeviceType,
	OrderReceiptRequestType,
	hardwareInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { AdminPosInfoState } from '../Admin/AdminPosInfoState';
import { OrderState, OrderStateType } from '../PosOrder/OrderState';
import { StoreInfoState } from '../StoreInfoState';
import { KioskElectronStoreState, PosElectronStoreState } from '../ElectronStoreState';

export const useKitchenPrinter = ({
	storeId,
	deviceType,
}: {
	storeId: number;
	deviceType: DeviceType;
}) => {
	const { receiptPrinterList: RPList, printMode } = StoreInfoState.useCurDeviceInfoForOrder();
	const deviceInfoList = AdminPosInfoState.usePosAndKioskSelectorList({ storeId });
	const localSettings =
		deviceType === 'POS'
			? PosElectronStoreState.usePosMainPageLocalSettings().data
			: KioskElectronStoreState.useKioskMainPageLocalSettings().data;

	return {
		printKitchen주문서Async: async ({
			order,
			주방주문증크게 = false,
			취소주문증인지 = false,
		}: {
			order: OrderStateType;
			주방주문증크게?: boolean;
			취소주문증인지?: boolean;
		}) => {
			if (!OrderState.cart.utils.hasKitchenReceipt(order.cart.lines)) {
				return;
			}

			const deviceInfo = deviceInfoList.find((d) => d.device.id === order.posId);
			const kitchenReceipt = OrderState.cart.utils
				.getOrderListFromCart(order.cart.lines, 'kitchen')
				.filter((ocl) => ocl.isForKitchen);

			// ESCPOS 든 BIXOLONSDK 든 영수증 프린트 하면 안 되는 상황
			// kitchen Receipt 길이가 0일때
			// 주문에 kitchen Receipt 옵션이 있는지 검사
			if (!kitchenReceipt.length) {
				return;
			}

			const CandidatePrinterInfos = RPList.filter(
				(p) => p.hardwareType.usageType === 'KITCHEN' && p.isActive
			);
			for (const printerInfo of CandidatePrinterInfos) {
				for (let i = 0; i < printerInfo.quantity; i++) {
					if (printerInfo.regex) {
						const regex = new RegExp(printerInfo.regex);

						const kitchenReceiptIdList: string[] = [];
						const kitchenReceiptFiltered = kitchenReceipt.filter((kl) => {
							if (!kl.targetCartLineId) {
								const isInlcuded = regex.test(kl.origin?.name ?? '');

								if (isInlcuded) kitchenReceiptIdList.push(kl.cartLineId);
								return isInlcuded;
							} else {
								return kitchenReceiptIdList.includes(kl.targetCartLineId);
							}
						});

						if (kitchenReceiptFiltered.length > 0)
							await printKitchenReceipt({
								hardwareInfo: {
									deviceType: deviceType,
									printerInfo: printerInfo,
									printMode,
									영수증너비: localSettings
										? localSettings.영수증너비
										: deviceType === 'POS'
										? 42
										: 48,
								},
								orderDeviceName: deviceInfo
									? `[${deviceInfo.type}] ${deviceInfo.device.name}`
									: '-',
								kitchenReceipt: kitchenReceiptFiltered,
								dailySeq: order.dailySeq ?? 0,
								largeFont: 주방주문증크게,
								isCancel: 취소주문증인지,
							});
					} else {
						await printKitchenReceipt({
							hardwareInfo: {
								deviceType: deviceType,
								printerInfo: printerInfo,
								printMode,
								영수증너비: localSettings
									? localSettings.영수증너비
									: deviceType === 'POS'
									? 42
									: 48,
							},
							orderDeviceName: deviceInfo ? `[${deviceInfo.type}] ${deviceInfo.device.name}` : '-',
							kitchenReceipt,
							dailySeq: order.dailySeq ?? 0,
							largeFont: 주방주문증크게,
							isCancel: 취소주문증인지,
						});
					}
				}
			}
		},
	};
};

const printKitchenReceipt = async ({
	hardwareInfo,
	orderDeviceName,
	kitchenReceipt,
	dailySeq,
	largeFont,
	isCancel,
}: {
	hardwareInfo: hardwareInfoType;
	orderDeviceName: string;
	kitchenReceipt: (OrderReceiptRequestType & {
		cartLineId: string;
		targetCartLineId?: string | undefined;
	})[];
	dailySeq: number;
	largeFont: boolean;
	isCancel: boolean;
}) => {
	await ReactRPIPC.invoke주방영수증출력({
		hardwareInfo,
		order: kitchenReceipt,
		orderDeviceName,
		foodReceiptId: dailySeq,
		orderDate: new Date(),
		largeFont,
		isCancel,
	});
};
